const firebaseConfig = {
    apiKey: "AIzaSyDtxb05buYfimi8WG24C7VXO5X8x_qKMXM",
    authDomain: "adoisjota-vote-map.firebaseapp.com",
    projectId: "adoisjota-vote-map",
    storageBucket: "adoisjota-vote-map.appspot.com",
    messagingSenderId: "838555350730",
    appId: "1:838555350730:web:c6eec7398270fa64e7e633",
    measurementId: "G-B28963G7BC"
  };

// Importações modulares do Firebase v9+
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const app = initializeApp(firebaseConfig);
// Exporta as instâncias dos serviços do Firebase que você vai usar
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();