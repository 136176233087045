import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

const AuthJoin = () => import("@/pages/Auth/Join.vue");

const UsersList = () => import("@/pages/Users/UsersList.vue");
const UsersNew = () => import("@/pages/Users/UsersNew.vue");
const UsersEdit = () => import("@/pages/Users/UsersEdit.vue");

const CabosEleitoraisList = () => import("@/pages/CabosEleitorais/CabosEleitoraisList.vue");
const CabosEleitoraisNew = () => import("@/pages/CabosEleitorais/CabosEleitoraisNew.vue");
const CabosEleitoraisEdit = () => import("@/pages/CabosEleitorais/CabosEleitoraisEdit.vue");

const Relatorios = () => import("@/pages/Relatorios/Relatorios.vue");
const RelatoriosVisitasPorCaboEleitoral = () => import("@/pages/Relatorios/RelatoriosVisitasPorCaboEleitoral.vue");
const RelatoriosVisitasCompleto = () => import("@/pages/Relatorios/RelatoriosVisitasCompleto.vue");
const RelatoriosVisitasCompletoDetalhes = () => import("@/pages/Relatorios/RelatoriosVisitasCompletoDetalhes.vue");

const PoliticaPrivacidade = () => import("@/pages/Site/PoliticaPrivacidade.vue");
const MapsCabosEleitorais = () => import("@/pages/Maps/MapsCabosEleitorais.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "politica-privacidade",
        name: "Política de Privacidade",
        component: PoliticaPrivacidade,
        meta: {
          layout: 'guest',
        },
      },
      {
        path: "join",
        name: "join",
        component: AuthJoin,
        meta: {
          layout: 'guest',
        },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "mapa/cabos-eleitorais",
        name: "Mapa",
        component: MapsCabosEleitorais,
      },
      {
        path: "usuarios",
        name: "Usuários",
        component: UsersList,
      },
      {
        path: "usuarios-new",
        name: "Usuários",
        component: UsersNew,
      },
      {
        path: "usuarios-edit/:id",
        name: "Usuários",
        component: UsersEdit,
      },
      {
        path: "cabos-eleitorais",
        name: "Cabos Eleitorais",
        component: CabosEleitoraisList,
      },
      {
        path: "cabos-eleitorais-new",
        name: "Cabos Eleitorais",
        component: CabosEleitoraisNew,
      },
      {
        path: "cabos-eleitorais-edit/:id",
        name: "Cabos Eleitorais",
        component: CabosEleitoraisEdit,
      },
      {
        path: "relatorios",
        name: "Relatórios",
        component: Relatorios,
      },
      {
        path: "relatorios/visitas-por-cabo-eleitoral",
        name: "Relatórios",
        component: RelatoriosVisitasPorCaboEleitoral,
      },
      {
        path: "relatorios/visitas-completo",
        name: "Relatórios",
        component: RelatoriosVisitasCompleto,
      },
      {
        path: "relatorios/visitas-completo/:id",
        name: "Relatórios",
        component: RelatoriosVisitasCompletoDetalhes,
      }
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
